<template>
  <div class="logorder-continer">
    <div class="logorder-head">
        <div class="head-left">
            <div class="head-input">
                <div style="display: flex">
                    <span class="form-label"
                          style="
                display: inline-block;
                font-size: 12px;
                white-space: nowrap;
                line-height: 28px;
              ">物流单号：</span>
                    <el-input v-model="input"
                              placeholder="请输入物流单号"
                              class="input-order"
                              size="mini"></el-input>
                </div>
            </div>
            <div class="head-input">
                <div style="display: flex">
                    <span class="form-label"
                          style="
                display: inline-block;
                font-size: 12px;
                white-space: nowrap;
                line-height: 28px;
              ">收件人电话：</span>
                    <el-input v-model="phone"
                              placeholder="请输入收件人电话"
                              size="mini"
                              class="input-order"></el-input>
                </div>
            </div>
            <div class="head-select">
                <div style="display: flex">
                    <span class="form-label"
                          style="
                display: inline-block;
                font-size: 12px;
                white-space: nowrap;
                line-height: 28px;
              ">订单来源：</span>
                    <el-select slot="prepend"
                               v-model="select3"
                               placeholder="全部"
                               size="mini"
                               class="input-order">
                        <el-option v-for="item in service"
                                   :key="item.sourceServiceId"
                                   :label="item.sourceServiceName"
                                   :value="item.sourceServiceId" />
                    </el-select>
                </div>
            </div>

            <div class="head-select">
                <div style="display: flex">
                    <span class="form-label"
                          style="
                display: inline-block;
                font-size: 12px;
                white-space: nowrap;
                line-height: 28px;
              ">物流品牌：</span>
                    <el-select slot="prepend"
                               v-model="select"
                               placeholder="全部"
                               size="mini"
                               class="input-order">
                        <el-option v-for="item in courier"
                                   :key="item.companyId"
                                   :label="item.name"
                                   :value="item.companyId" />
                    </el-select>
                </div>
            </div>
            <div class="head-select">
                <div style="display: flex">
                    <span class="form-label"
                          style="
                display: inline-block;
                font-size: 12px;
                white-space: nowrap;
                line-height: 28px;
              ">物流订单状态：</span>
                    <el-select slot="prepend"
                               v-model="select2"
                               placeholder="全部"
                               size="mini"
                               @change="states"
                               class="input-order">
                        <el-option label="全部" value="0"> </el-option>
                        <el-option v-for="item in orderstatus"
                                   :key="item.stateId"
                                   :label="item.stateName"
                                   :value="item.stateId" />
                    </el-select>
                </div>
            </div>
            <div class="head-input">
                <div style="display: flex">
                    <span class="form-label"
                          style="
                display: inline-block;
                font-size: 12px;
                white-space: nowrap;
                line-height: 28px;
              ">时间段：</span>
                    <el-date-picker v-model="dateRangeVal"
                                    type="daterange"
                                    size="mini"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                    </el-date-picker>
                </div>
            </div>
        </div>
      <div class="head-right">
        <el-button type="primary" width="50px" size="mini" @click="seek()"
          >搜索</el-button
        >
        <el-button size="mini" width="50px" type="info" @click="reset"
          >重置</el-button
        >
        <el-button size="mini" width="50px" @click="doOut">导出</el-button>
        <el-button size="mini" width="50px" v-if="select2 == 1" @click="bqPrint"
          >打印</el-button
        >
      </div>
    </div>
    <div class="logorder-center">
      <div class="center-head">
        <!-- <el-button size="mini" v-for="item in  orderstatus" :key="item.stateId" @click="conditionFn(item.stateId)">{{item.stateName}}  (111)</el-button> -->
        <el-menu default-active="0" class="el-menu-demo" mode="horizontal">
          <el-menu-item index="0" @click="alldd"> 全部 </el-menu-item>
          <el-menu-item
            v-for="(item, index) in orderstatus"
            :key="item.stateId"
            :index="(index + 1).toString()"
            @click="conditionFn(item.stateId)"
            >{{ item.stateName }}</el-menu-item
          >
        </el-menu>
      </div>
      <div class="center-table">
        <el-table
          ref="table"
          @selection-change="selectL"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{ background: '#d0e0f7' }"
          max-height="400"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            align="center"
            fixed
            prop="index"
            label="序号"
            width="50"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="wayBillNo"
            label="物流单号"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="goodsType"
            label="包裹类别"
            width="160"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="senderName"
            label="寄件人"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="sourceServiceName"
            label="订单来源"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="senderAddress"
            label="发货地"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="receiverAddress"
            label="收货地"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="receiverMobile"
            label="收件人电话"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="expressName"
            label="物流品牌"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="estimatedAmount"
            label="物流预估费用"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="actualAmount"
            label="物流实际费用"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="couponName"
            label="优惠券名称"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="couponNo"
            label="优惠券编号"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="couponAmount"
            label="优惠金额（单位元）"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="isInsuredPrice"
            label="是否保价"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="insuredPriceAmount"
            label="保价费用（单位元）"
            width="150"
          >
          </el-table-column>
              <el-table-column
            align="center"
            prop="firstFreight"
            label="首次计重费用"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="actualPayAmount"
            label="物流实结金额"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="estimatedWeight"
            label="预估重量（单位kg）"
            width="160"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="estimatedVloumn"
            label="预估体积(单位cm³）"
            width="160"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="stateName"
            label="物流订单状态"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="timeSendOutGoods"
            label="发货时间"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="timePickup"
            label="揽件时间"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="timeSignFor"
            label="签收时间"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="timeCompletion"
            label="物流订单完成时间"
            width="160"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="timeSettlement"
            label="结算时间"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="cancelTime"
            label="取消发货时间"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="cancelType"
            label="取消发货类型"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="cancelReason"
            label="取消发货原因"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="cancelUserName"
            label="取消操作人"
            width="150"
          >
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="200">
            <template slot-scope="scope">
              <el-button
                v-for="(item, index) in scope.row.button"
                :key="index"
                @click.native.prevent="look(scope.row, item)"
                type="text"
                size="small"
              >
                {{ item }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="center-table acc">
        <el-table
          ref="tables"
          :data="listSelect"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{ background: '#d0e0f7' }"
          max-height="400"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
          <el-table-column
            align="center"
            fixed
            prop="index"
            label="序号"
            width="50"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="wayBillNo"
            label="物流单号"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="goodsType"
            label="包裹类别"
            width="160"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="senderName"
            label="寄件人"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="sourceServiceName"
            label="订单来源"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="senderAddress"
            label="发货地"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="receiverAddress"
            label="收货地"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="receiverMobile"
            label="收件人电话"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="expressName"
            label="物流品牌"
            width="120"
          >
          </el-table-column>
        
          <el-table-column
            align="center"
            prop="estimatedAmount"
            label="物流预估费用"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="actualAmount"
            label="物流实际费用"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="couponName"
            label="优惠券名称"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="couponNo"
            label="优惠券编号"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="couponAmount"
            label="优惠金额（单位元）"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="isInsuredPrice"
            label="是否保价"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="insuredPriceAmount"
            label="保价费用（单位元）"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="actualPayAmount"
            label="物流实结金额"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="estimatedWeight"
            label="预估重量（单位kg）"
            width="160"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="estimatedVloumn"
            label="预估体积(单位cm³）"
            width="160"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="stateName"
            label="物流订单状态"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="timeSendOutGoods"
            label="发货时间"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="timePickup"
            label="揽件时间"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="timeSignFor"
            label="签收时间"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="timeCompletion"
            label="物流订单完成时间"
            width="160"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="timeSettlement"
            label="结算时间"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="cancelTime"
            label="取消发货时间"
            width="120"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="cancelType"
            label="取消发货类型"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="cancelReason"
            label="取消发货原因"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="cancelUserName"
            label="取消操作人"
            width="150"
          >
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="200">
            <template slot-scope="scope">
              <el-button
                v-for="(item, index) in scope.row.button"
                :key="index"
                @click.native.prevent="look(scope.row, item)"
                type="text"
                size="small"
              >
                {{ item }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[10, 30, 50]"
          :page-size="100"
          layout="total, sizes, prev, pager, next"
          :total="num2"
        >
        </el-pagination>
      </div>
      <el-dialog title="发货" width="400px" :visible.sync="shipments">
        <div class="shipment-head" style="height: 40px">
          <div class="ship-left">基本信息</div>
          <div class="ship-right">
            <el-button type="success" @click="bc" size="mini">保存</el-button>
          </div>
        </div>
        <div class="shipment-center">
          <div class="ship-list">
            <div class="shlist-left">发件人</div>
            <div class="shlist-right">{{ all.senderName }}</div>
          </div>
          <div class="ship-list">
            <div class="shlist-left">联系电话</div>
            <div class="shlist-right">{{ all.senderMobile }}</div>
          </div>
          <div class="ship-list">
            <div class="shlist-left">省市区</div>
            <div class="shlist-right">
              {{ all.senderProvince + all.sendcerCity + all.senderCounty }}
            </div>
          </div>
          <div class="ship-list">
            <div class="shlist-left">详细地址</div>
            <div class="shlist-right">{{ all.senderAddress }}</div>
          </div>
          <div class="ship-list">
            <div class="shlist-left">收件人</div>
            <div class="shlist-right">{{ all.receiverMobile }}</div>
          </div>
          <div class="ship-list">
            <div class="shlist-left">收件人电话</div>
            <div class="shlist-right">{{ all.receiverMobile }}</div>
          </div>
          <div class="ship-list">
            <div class="shlist-left">省市区</div>
            <div class="shlist-right">
              {{ all.receiverProvince + all.receiverCity + all.receiverCounty }}
            </div>
          </div>
          <div class="ship-list">
            <div class="shlist-left">详细地址</div>
            <div class="shlist-right">{{ all.receiverAddress }}</div>
          </div>
          <div class="ship-list">
            <div class="shlist-left">收件时间</div>
            <div class="shlist-right">
              <el-select
                class="set"
                size="mini"
                v-model="timess"
                placeholder="请选择"
              >
                <el-option
                  width="191px"
                  v-for="item in sslist"
                  :key="item.value"
                  :label="item.label"
                  :value="
                    item.startHour +
                    ':' +
                    item.startMinute +
                    ':' +
                    item.startSecond +
                    '——' +
                    item.endHour +
                    ':' +
                    item.endMinute +
                    ':' +
                    item.endSecond
                  "
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="ship-list">
            <div class="shlist-left">预估重量</div>
            <div class="shlist-right">{{ all.estimatedWeight }}</div>
          </div>
          <div class="ship-list">
            <div class="shlist-left">预估体积</div>
            <div class="shlist-right">{{ all.estimatedVloumn }}</div>
          </div>
          <div class="ship-list">
            <div class="shlist-left">包裹类型</div>
            <div class="shlist-right">{{ all.goodsType }}</div>
          </div>
          <div class="ship-list">
            <div class="shlist-left">预估物流费用</div>
            <div class="shlist-right">{{ monery }}</div>
          </div>
          <div class="ship-list shih">
            <div class="shlist-left">添加备注</div>
            <div class="shlist-right">{{ all.remark }}</div>
          </div>
        </div>
        <div class="ship-end">
          <div class="end-a">物流公司</div>
          <div class="send-left">
            <el-select
              @change="pric"
              class="set"
              size="mini"
              v-model="sperson"
              placeholder="请选择"
            >
              <el-option
                v-for="item in wllist"
                :key="item.id"
                :label="item.companyName"
                :value="item"
              ></el-option>
            </el-select>
          </div>
          <div class="send-right">
            <!-- <el-button @click="mone" type="warning" size="mini">预估运费</el-button> -->
          </div>
        </div>
      </el-dialog>
    </div>
    <div class="endds">
      <div class="end-right" ref="endRight">
        <!-- <span>模板图片（宽{{form.width}}mm*高{{form.height}}mm）</span> -->
        <div class="right-img" id="right-img">
          <img
            class="right-p"
            v-for="(item, index) in imgurlarr"
            :key="index"
            :src="item.url"
            alt=""
          />
          <!-- <img src="../../assets/images/logBack.png" alt=""> -->
        </div>
      </div>
    </div>
  </div>
  <!-- https://files.lawxp.com:443/amb/1656040119/HFZXXH7…23b598c23d015adaee7695e4fea275e1a6a5f418a93562c20 -->
</template>

<script>
import printHtml from "../../utils/print1";
import { GetList, CloudPrintWaybillFileUrlList } from "../../api/setkd";
import {
  logorderggetlist,
  OrderState,
  courier,
  service,
  personList,
  CancelOrder,
  EstimatePriceRange,
  SendOutGoods,
  Export,
} from "../../api/logorder";
export default {
  data() {
    return {
      isurlshow: true,
      imgurlarr: [],
      urllistarr: [],
      value1: [new Date(2016, 9, 10, 8, 40), new Date(2016, 9, 10, 9, 40)],
      listSelect: [],
      input: "", //物流单号
      phone: "", //收件人电话
      select: "", //快递类型
      select2: "", //状态
      // input2: '', //订单编号
      select3: "", //订单来源
      currentPage4: 1, //当前页
      sum: 1, //总条数
      pageSize: 10, //每页几条
      tableData: [], //订单列表
      orderstatus: [], //订单状态列表
      courier: [], //快递公司列表
      service: [], //所属平台
      activeIndex: 1,
      shipments: false, //发货，编辑弹窗
      sslist: [], //取件时间列表
      timess: "", //取件时间
      wllist: [], //物流公司列表
      sperson: "", //物流公司
      all: {}, //返货弹窗内荣
      monery: "", //预估物流费用
      search_time: "", //当天年月日
      id: "", //主键id
      comyid: "", //物流公司id
      num2: 0,
      dateRangeVal: ''
    };
  },
  methods: {
    printJson() {
      this.$print(this.$refs.tables);
    },
    // 选中添加/不选中删除
    selectL(selection, row) {
      this.urllistarr = [];
      this.listSelect = selection;
      // if(this.listSelect.length=0){
      //   this.urllistarr=[]
      // }
      for (var i = 0; i < this.listSelect.length; i++) {
        let dd = { wayBillNo: this.listSelect[i].wayBillNo };
        this.urllistarr.push(dd);
      }
      // console.log(this.urllistarr);
      // console.log(this.listSelect,222222222222222);
    },
    bqPrint(d) {
      console.log(this.urllistarr.length);
      if (this.urllistarr.length > 0) {
        let data = this.urllistarr;
        CloudPrintWaybillFileUrlList(data).then((res) => {
          console.log(res);
          if ((res.code = 10000)) {
            this.imgurlarr = res.data;
            let that = this;
            let num = this.imgurlarr.length; //num是填写的打印页数
            let obj = { 2: 1 };
            this.$nextTick(() => {
              //this.$nextTick是强制刷新页面，防止打印的内容为空
              setTimeout(function () {
                //定时器延时是等待页面数据刷新完在打印
                console.log(that.$refs.endRight);
                let newstr = document.getElementById("right-img").innerHTML; //newstr为打印的页面
                printHtml(newstr); //调用引入的文件方法printHtml
              }, 500);
            });

            setTimeout(() => {
              this.imgurlarr = [];
            }, 2000);
          } else {
            this.$message(res.msg);
          }
        });
      } else {
        this.$message("请选择打印数据");
      }
      // console.log(this.$refs.endRight);
      // let that=this
      // 		// this.arrList = ['https://files.lawxp.com:443/amb/1656040119/HFZXXH7…23b598c23d015adaee7695e4fea275e1a6a5f418a93562c20','https://files.lawxp.com:443/amb/1656040119/HFZXXH7…23b598c23d015adaee7695e4fea275e1a6a5f418a93562c20']//分页的空数组，里面有几个对象就有几页
      // 		// this.modalNameList = d
      // 		let num =2//num是填写的打印页数
      // 		let obj = {2:2}
      // 		// for (let i = 0; i < num; i++) {
      // 		// 	this.arrList.push(obj)
      // 		// }
      // 		// this.modalPrintShow = true
      // 		this.$nextTick(() => {//this.$nextTick是强制刷新页面，防止打印的内容为空
      // 			setTimeout(function () {//定时器延时是等待页面数据刷新完在打印
      //       console.log( that.$refs.endRight);
      // 				let newstr =document.getElementById("right-img").innerHTML//newstr为打印的页面
      // 				printHtml(newstr)//调用引入的文件方法printHtml
      // 			},500)
      // 		})
    },

    states(val) {
      console.log(val);
    },
    alldd() {
      this.select2 = "全部";
      let data = {
        pageSize: this.pageSize,
        pageIndex: this.currentPage4,
        state: 0,
        wayBillNo: this.input,
        receiverMobile: this.phone,
        sourceServiceId: Number(this.select3),
        dataSourceType: 2,
      };
      // console.log(data)
      logorderggetlist(data).then((res) => {
        console.log(res);
        if (res.code === 10000) {
          this.tableData = res.data;
          this.num2 = res.total;
        }
      });
    },
    //重置
    reset() {
      this.input = "";
      this.phone = "";
      this.select = "";
      this.select3 = "";
      this.select2 = "";
      this.getlogorlist();
    },
    //获取时间列表
    qgetlist() {
      GetList({ type: 1 })
        .then((res) => {
          // console.log(res);
          if (res.code === 10000) {
            this.sslist = res.data;
            console.log(this.sslist);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取发件人列表
    getperson() {
      let data = {
        personalAddressType: 1,
      };
      personList()
        .then((res) => {
          console.log(res);
          if (res.code === 10000) {
            this.persons = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //获取收件人列表
    getperson() {
      let data = {
        personalAddressType: 2,
      };
      personList()
        .then((res) => {
          console.log(res);
          if (res.code === 10000) {
            this.persons2 = res.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      console.log(this.pageSize);
      this.getlogorlist();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentPage4 = val;
      this.getlogorlist();
    },
    //订单列表
    getlogorlist() {
      let data = {
        pageSize: this.pageSize,
        pageIndex: this.currentPage4,
        state: this.select2?Number(this.select2):0,
        wayBillNo: this.input,
        receiverMobile: this.phone,
        sourceServiceId: Number(this.select3),
        dataSourceType: 2,
        companyId: Number(this.select),
        timeBegin: (this.dateRangeVal && this.dateRangeVal[0]) ? this.dateRangeVal[0] : "",
        timeEnd: (this.dateRangeVal && this.dateRangeVal[1]) ? this.dateRangeVal[1] : "",
      };
      // console.log(data)
      logorderggetlist(data)
        .then((res) => {
          console.log(res);
          if (res.code === 10000) {

            this.tableData = res.data;  
            this.num2 = res.total;
          }
        })
        .catch((err) => { 
          console.log(err);
        });
    },
    doOut() {
      let data = {
        pageSize: this.pageSize,
        pageIndex: this.currentPage4,
        state: this.select2 ? Number(this.select2) : 0,
        wayBillNo: this.input,
        receiverMobile: this.phone,
        sourceServiceId: Number(this.select3),
        dataSourceType: 2,
        companyId: Number(this.select),
        timeBegin: (this.dateRangeVal && this.dateRangeVal[0]) ? this.dateRangeVal[0] : "",
        timeEnd: (this.dateRangeVal && this.dateRangeVal[1]) ? this.dateRangeVal[1] : "",
      };
      // return window.location.href = "https://files.lawxp.com:443/amb/1630289965.xlsx?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=lawxp%2F20210830%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20210830T021924Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=3a3be70ddc5b91955dcf915392263b5652d6e35d7a08e2722a25895c6bd2140a";
      Export(data)
        .then((res) => {
          if (res.code == 10000) {
            window.location.href = res.data;
            console.log(res.data);
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //状态
    getOrderState() {
      OrderState()
        .then((res) => {
          if (res.code === 10000) {
            this.orderstatus = res.data;
            console.log(this.orderstatus);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //快递公司
    getcourier() {
      courier()
        .then((res) => {
          // console.log(res)
          if (res.code === 10000) {
            this.courier = res.data;
            // console.log(this.courier)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //订单来源
    getService() {
  service({ fromWhere:2})
        .then((res) => {
          // console.log(res)
          if (res.code === 10000) {
            this.service = res.data;
            // console.log(this.courier)
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    look(val, item) {
      // console.log(val. logisticsCourierId)
      if (item === "查看订单详情") {
        this.$router.push({
          path: "/detail",
         query: { id: val.logisticsCourierId },
        });
      } else if (item === "取消订单") { 
        console.log(val);
        let data = {
          id: val.logisticsCourierId,
          waybillNo: val.wayBillNo, //运单号
          remark: val.cancelReason, //取消原因
        };
        this.$confirm("此操作是取消订单, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            CancelOrder(data)
              .then((res) => {
                console.log(res);
                if (res.code === 10000) {
                  this.$message({
                    type: "success",
                    message: "取消成功!",
                  });
                  this.getlogorlist();
                } else {
                  this.$message("取消失败");
                }
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消此操作",
            });
          });
      } else if (item === "发货") {
        console.log(val);
        this.id = val.logisticsCourierId;
        this.all = val;
        this.shipments = true;
        let data = {
          startProv: this.all.senderProvince,
          startCity: this.all.sendcerCity,
          startCountry: this.all.senderCounty,
          startAddress: this.all.senderAddress, //始发详细地址
          endProv: this.all.receiverProvince, //目的省份
          endCity: this.all.receiverCity, //目的城市
          endCountry: this.all.receiverCounty, //目的县
          endAddress: this.all.receiverAddress, //目的详细地址
          goodsList: [
            {
              weight: this.all.estimatedWeight,
            },
          ],
        };
        EstimatePriceRange(data)
          .then((res) => {
            console.log(res);
            if (res.code === 10000) {
              this.wllist = res.data;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (item === "打印") {
        console.log(val.wayBillNo);
        this.$router.push({
          path: "/odd",
          query: {
            way: val.wayBillNo,
            logisticsCompanyId: val.logisticsCompanyId,
          },
        });
      } else if (item === "编辑") {
        // console.log(val.logisticsCourierId);
        this.$router.push({
          name: "shipments",
          params: { way: val.logisticsCourierId },
        });
      }
    },
    pric(val) {
      console.log(val);
      this.sperson = val.companyName;
      this.monery = val.price;
      this.comyid = val.companyId;
    },
    //搜索
    seek() {
      // alert(11)
      this.getlogorlist();
    },
    //状态查找
    conditionFn(val) {
      this.select2 = val;
      console.log(val);
      this.getlogorlist();
    },
    //保存发货
    bc() {
      let time1 = this.timess.slice(0, 8);
      let time2 = this.timess.slice(10, 18);
      let oldtime = this.search_time + " " + time1;
      let newtime = this.search_time + " " + time2;
      let data = {
        id: this.id,
        logisticsCompanyCode: Number(this.comyid),
        sendStartTime: oldtime,
        sendEndTime: newtime,
      };
      SendOutGoods(data)
        .then((res) => {
          console.log(res);
          if (res.code == 10000) {
            this.$message("发货成功");
            this.shipments = false;
          } else {
            this.$message(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTime() {
      let date = new Date();
      console.log(date);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate() >= 10 ? date.getDate() : "0" + date.getDate();
      if (month < 10) {
        this.search_time = year + "-" + "0" + month + "-" + day;
      } else {
        this.search_time = year + "-" + month + "-" + day;
      }
    },
  },
  created() {
    // console.log(this.pageSize)
    this.getTime();
    this.qgetlist();
    this.getOrderState();
    this.getcourier();
    this.getService();
    this.getlogorlist();
    this.getperson();
    // this.getCompany();
  },
};
</script>

<style scoped>
.logorder-continer {
  width: 100%;
  height: 100vh-80px;
  position: relative;
  /* height: 500px; */
  /* padding: 10px 10px 10px 10px; */
  /* box-sizing: border-box; */
  /* background: #ddd; */
}
.endds {
  position: absolute;
  left: 50%;
  bottom: -800px;
  display: none;
}
.logorder-head {
  width: 100%;
  height: 100px;
  background: white;
  border-radius: 5px;
  padding: 10px 0 0 5px;
  margin-top: 20px;
  display: flex;
}
.head-left {
  display: flex;
  flex-wrap: wrap;
  flex: 5;
  /* float: left; */
}
.head-right {
  flex: 1;
  /* float: right; */
}
.head-input {
  margin-right: 15px;
  margin-bottom: 20px;
}
.head-select {
  margin-right: 15px;
  margin-bottom: 20px;
}
.head-right /deep/ .el-button--mini {
  width: 50px;
}
.logorder-center {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 5px;
  margin-top: 20px;
  padding: 10px 0 0 0;
  box-sizing: border-box;
}
.block {
  height: 70px;
  padding: 15px 0 0 0;
  box-sizing: border-box;
}
/deep/ .my-label {
  background: #ccff99;
}
.ship-left {
  float: left;
}
.ship-right {
  float: right;
}
.ship-list {
  width: 356px;
  height: 33px;
  border: 1px solid black;
  border-bottom: none;
}
.shlist-left {
  float: left;
  width: 30%;
  height: 100%;
  border-right: 1px solid black;
  text-align: center;
  line-height: 33px;
  font-size: 14px;
  background: #ccff66;
}
.shlist-right {
  width: 65%;
  float: left;
  height: 100%;
  text-align: center;
  line-height: 33px;
  font-size: 14px;
}
/deep/ .shipment-center .el-input__inner {
  border: none !important;
  color: black;
}
/deep/ .shlist-right input:focus {
  outline: none;
}
/deep/ .shlist-right input {
  border: 0;
}
.shih {
  border-bottom: 1px solid black;
}
.ship-end {
}
.send-left {
  float: left;
}
.send-right {
  float: right;
}
.end-a {
  font-size: 14px;
  color: black;
  margin-bottom: 5px;
  margin-top: 5px;
}
.wsp {
  white-space: nowrap;
  font-size: 12px;
}
.shlist-right {
  overflow: hidden; /*溢出的部分隐藏*/
  white-space: nowrap; /*文本不换行*/
  text-overflow: ellipsis; /*ellipsis:文本溢出显示省略号（...）；clip：不显示省略标记（...），而是简单的裁切*/
}
.acc {
  display: none;
}
.right-p {
  width: 100%;
  height: 100%;
}

.form-label {
  width: 100px;
  display: inline-block;
  text-align: end;
  flex: none;
}

/deep/.el-input {
  width: 150px;
}
::v-deep .el-select {
  width: 150px;
}



</style>
