import request from '../utils/request'
import baseURL from '../utils/config'

//新增取件时间
export function AddOrEdit(data) {
  return request({
      url: baseURL.baseURL + 'api/Adm_PickupTime/AddOrEdit',
      method: 'post',
      data
  })
}
//取件时间列表
export function GetList(params) {
    return request({
        url: baseURL.baseURL + 'api/Adm_PickupTime/GetList',
        method: 'get',
        params
    })
  }
  //删除
export function Delete(data) {
    return request({
        url: baseURL.baseURL + 'api/Adm_PickupTime/Delete',
        method: 'get',
        params:data
    })
  }

    //url批量
export function CloudPrintWaybillFileUrlList(data) {
  return request({
      url: baseURL.baseURL + 'api/Synthetical/GetCloudPrintWaybillFileUrlList',
      method: 'post',
     data
  })
}

